
.footer { 

   /* margin-top: 100vh; */
   
   padding-top:20px;
   margin-bottom: 0;
    text-align: center;
    /* padding-bottom: 5%; */
    
    background-color:#171c24;
    width: 100%;
    display: flex;
    flex-direction: row;
    /* display: grid;
    grid-template-columns: repeat(2,1fr); */
  
}
.footer .left {
    color:#fff;
    /* margin-left: 50px; */
    /* margin-right: 15px; */
    
}

.footer .center .signup{
   
    width:100%;
    margin-left: 0;
    margin-right: 0;
    background-color:#171c24;
    padding: 5px;
    
}
.signup label{
    width: 80%;
    color: #fff;
    margin-bottom: 10px;
    
}
.signup input{
    width:70%;
    height: 35px;
    border-radius: 5px;
    margin-bottom: 10px;
} 
.signup button{
    text-align: center;
    width:40%;
    height: 35px;
    border-radius: 20px;
    background-color: #fff;
    color:#171c24 ;
    font-style:bold;

}
.signup button:hover{
    
    color: green ;
    font-style: italic;
}
.footer .right{
    font-size: 10px;
    align-items: center;
text-align: center;
}
.footer .right a{      
    color:#fff;
    font-size: 15px;
}
.footer .right a:hover{
    color: green;
}

.footer .right ul{
    list-style-type:none;
    /* padding: 15px; */  
}

ul li{
    display: inline;
    padding-right: 10px;
    padding-left: 10px;
}
ul li a{
    font-size: 30px;
    color:#fff;
}
ul li a:hover{
    color:green;
}
@media screen and (max-width:900px) {
    .footer{
       
        height: 20%;
        align-self: flex-end;
    }
}
@media screen and (max-width:600px) {
    .footer{
  
        /* padding-top: 100%-20px;   */
          grid-template-columns: repeat(1,1fr);
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    /* margin-top: 100vh; */
        }
      
   
    }

    