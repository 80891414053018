.middle{

    border: 1px black solid;
    box-shadow: 1px;
  position: absolute;
  width: 400px;
  height: 300px;
  z-index: 15;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -200px;
 
}
Form{
    margin: 10px;
}