.myrow{
  height: 100%;
  margin-top: 10%;
  

}
.myrow .content{
  min-height: 100%;
  display: flex;
  flex-direction: row;
}

.myrow .content .leftcolumn{  
  height: 100%;
  z-index: 1;
  width: 80%;
  
  /* border-right:5px solid green; */
}

.myrow .content .rightcolumn{
  width: 20%;
  /* background-color: aqua; */
  z-index: 1;

  /* visibility: hidden; */
}

.myrow .content .rightcolumn  .listgroup {
  background-color: #030;
}
.myrow .content .rightcolumn .listgroup panel.active, .listgroup panel.active a.active {
  background-color: #030;
  border-color: #aed248;
}
.myrow .content .rightcolumn .listgroupitem panel.active, .listgroup panel.active a.active {
  background-color: #030;
  border-color: #aed248;
}

/* .myrow .content .rightcolumn .rightdata{
  

}
.myrow .content .rightcolumn .rightdata .btnright{

  font-style:bold;
  border: none;
  background-color: white;
  text-align: left;
  font-size: 30px;
  }
  .myrow .content .rightcolumn .rightdata .btnright:hover{
    color: green;
  }
  .myrow .content .rightcolumn .rightdata .olright{
    font-size: 30px;
  } */
  /* .myrow .content .leftcolumn .carousel{
    width:900px;
    height:500px;
    margin: auto;
  } */
.myrow .content .leftcolumn .dealsdata{
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* margin-left: 5%; */

}
.myrow .content .leftcolumn .dealsdata .card{ 
  margin-top: 10px;
  margin: 10px;  
  border-bottom:  rgb(123, 13, 13) 1px solid;
  width: 20rem; 
  z-index: 1; 
  
}
.myrow .content .leftcolumn .dealsdata .card:hover{
border: rgb(12,203,12) 2px solid;
}


.myrow .content .leftcolumn .dealsdata .card .img{

align-self: center;
height: 250px;
width: 250px;
}
.myrow .content .leftcolumn .dealsdata .card #body{

color:#171c24;

}
.myrow .content .leftcolumn .loadmore{ 
text-align: center;
padding-top: 10px;
padding-bottom: 20px;

}
.myrow .content .leftcolumn .loadmore button{
text-align: center;
color:#fff;
border: 0;
padding:10px;
border-radius: 10px;
background-color: #171c24;
}
.myrow .content .leftcolumn .loadmore button:hover{
color:rgb(12, 203, 12);

}
.myrow .content .leftcolumn .spinner{
  /* background-color: aqua; */
  height: 100%;
  /* width:5%; */
  justify-content: center;  
  margin-left: 50%;
}
@media screen and (max-width:1250px){
  .myrow{
    margin-top: 20%;
  }  
  

  .myrow .content .leftcolumn{   
   
    z-index: 1;
    width: 100%;

  }

  .myrow .content .rightcolumn{
    width: 0%;
    visibility: hidden;
    /* background-color: bisque; */
  }
}
@media screen and (max-width:600px){
  .myrow{
    margin-top: 20%;
  }  
  

  .myrow .content .leftcolumn{   
   
    z-index: 1;
    width: 100%;

  }

  .myrow .content .rightcolumn{
    width: 0%;
    visibility: hidden;
    /* background-color: bisque; */
  }
  .myrow .content .leftcolumn .dealsdata{
    /* padding-left: 10%; */
    margin-left: 0;


  }
  .myrow .content .leftcolumn .dealsdata .card{ 
    margin-top: 10px;
    
    border-bottom:  rgb(123, 13, 13) 1px solid;
    width: 30rem; 
    z-index: 1;
    /* background-color: aqua; */
    
}
.myrow .content .leftcolumn .dealsdata .card:hover{
  border: rgb(12,203,12) 2px solid;
  
}


.myrow .content .leftcolumn .dealsdata .card .img{
 
  height: 200px;
  width: 200px;
}
.myrow .content .leftcolumn .dealsdata .card #body{
 
  color:#171c24;

}
.myrow .content .leftcolumn .loadmore{ 
  text-align: center;
  padding-top: 10px;
  padding-bottom: 20px;
  
}
.myrow .content .leftcolumn .loadmore button{
  text-align: center;
  color:#fff;
  border: 0;
  padding:10px;
  border-radius: 10px;
  background-color: #171c24;
}
.myrow .content .leftcolumn .loadmore button:hover{
  color:rgb(12, 203, 12);
  background-color: black;
}
@media screen and (max-width:300px){
  .myrow{
    margin-top: 20%;
  }  
  

  .myrow .content .leftcolumn{   
   
    z-index: 1;
    width: 100%;

  }

  .myrow .content .rightcolumn{
    width: 0%;
    visibility: hidden;
    /* background-color: bisque; */
  }
  .myrow .content .leftcolumn .dealsdata .card .img{
 
    height: 150px;
    width: 150px;
  }
}



}
