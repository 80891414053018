/* <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"> */
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none !important;
  font-family: 'Poppins', sans-serif;
  z-index: 2;
  /* padding-top: 5px; */
  
}

#navbardropdown{
  color: #fff;
  background-color: #171c24;
 
}
.navbar-toggler-icon{ 
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(250,250,250)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>") !important;

}
.wrapper{
  background: #171c24;
  position: fixed;  
  /* position: relative;  */
  width: 100%;
    top:0;
    
}
.wrapper nav{
  position: relative;
  display: flex;
  /* flex-direction: row-reverse; */
  max-width: calc(100% - 200px);
  margin: 0 auto;
  height: 70px;
 align-items: center;
  justify-content: space-between;
}
nav .content{
  display: flex;
  /* flex-direction:row-reverse; */
  align-items: center;
}
nav .content .links{
  margin-left: 80px;
  display: flex;
  /* list-style-type: none;*/

  /* overflow: hidden;  */
  background-color: #171c24;
}
/* nav .content .logo img{
  height: 70px;
  width: 100px;
} */
nav .content .logo a{
  color: #fff;
  font-size: 30px;
  font-weight: 600;
  
}
.content .links li{
  /* list-style: none;
  line-height: 10px; */
  float: left;
  
}

.content .links li a, .dropbtn { 
  text-align: center;
  text-decoration: none; 
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  border-radius: 5px;
  transition: all 0.3s ease;
  display:inline-block;
  padding: 14px 16px;
  
}


.content .links li a:hover, .dropdown:hover .dropbtn {
  /* background-color: #323c4e; */
  color: rgb(12, 203, 12);
}

.content .links li .dropdown {
  display: inline-block;
  width: 100%!important;
}

.content .links li .dropdown-content{
  position: absolute;
  background: #171c24;
  /* top: 80px; */
  /* z-index: 1;change */
  opacity: 0;
  visibility: hidden;
  /* min-width: 500px; */
  height:100%;  
}


.content .links li .dropdown-content .myrow{
  background-color: #171c24;
 
}
.content .links li .dropdown-content .column{
  background-color: #171c24;
   display: grid;
   grid-template-columns:  repeat(3, 1fr);
   grid-template-rows: auto;
  grid-gap: 8px;
  
}
.content .links li .dropdown-content a {
  color: #fff;
  padding: 10px 12px;
  text-decoration: none;
  display: block;
  text-align: left; font-size: 12px;
}
.content .links li:hover .dropdown-content {
  top: 50px;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ;
 
}

.content .links li .dropdown-content a:hover {
 
  /* background-color: #323c4e; */
  color: rgb(12, 203, 12);
}

.content .links li .dropdown:hover .dropdown-content {
  display: block;
}


.wrapper .search-icon,
.wrapper .menu-icon{
  
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  line-height: 70px;
  width: 70px;
  text-align: center;
  
}


.wrapper .menu-icon{
  display: none;
}
.wrapper #show-search:checked ~ .search-icon i::before{
  content: "\f00d";
}
/* .wrapper #show-menu:checked ~ .menu-icon i::before{
  content: "\f00d";
} */
.wrapper .search-box{
   margin: 0; 
  position: absolute;
  height: 100%;
  max-width: calc(100% - 50px);
  width: 100%;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;
  /* z-index: 0; */
}
.wrapper #show-search:checked ~ .search-box{
  opacity: 1;
  pointer-events: auto;
}

.search-box input{
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 17px;
  color: #fff;
  background: #171c24;
  padding: 0 100px 0 15px;
}
.search-box input::placeholder{
  color: #f2f2f2;
}
.search-box .go-icon{
  position: absolute;
  right: 10px;  
  top: 50%;
  transform: translateY(-50%);  
  line-height: 60px;
  width: 70px;
  background: #171c24;
  border: none;
  outline: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}
.wrapper input[type="checkbox"]{
  display: none;
}


  /* .footer {
    padding: 10px;
    text-align: center;
    background: rgb(197, 37, 37);
    margin-top: 20px;
    height: 500px;
  } */

/* responsive menu */

  @media screen and (max-width: 1250px){
    .wrapper nav{
      max-width: 100%;
      padding: 0 20px;
    }
    nav .content .links{
      margin-left: 30px;
    }
    .content .links li a{
      padding: 8px 13px;
    }
    .wrapper .search-box{
      max-width: calc(100% - 100px);
    }
    .wrapper .search-box input{
      padding: 0 100px 0 15px;
    }
  }


  @media screen and (max-width:900px) {
    
    .wrapper .menu-icon{
      display:block;
    }
    /* .wrapper .checkbox:checked ~ .menu-icon i::before{
      content: "\f00d";
    } */

    .wrapper #show-menu:checked ~ .menu-icon i::before{
      content: "\f00d";
    }

    nav .content .links{
      display:block;
       position:fixed;
      background-color: #171c24;
      height: 100%;
      width: 100%;
      top:70px;
      left: -100%;
      margin-left: 0;
      max-width: 350px;
      overflow-y: auto;
      padding-bottom: 100px;
      transition: all 0.3s ease; 
      scroll-behavior: auto;/*updated*/
      /* z-index: 0; */
    }
    .content .links li{
      margin:15px 20px;
    }

    nav #show-menu:checked ~ .content .links{
      left: 0%;
    }

    .content .links li a,
    .content .links li label{
      line-height: 40px;
      font-size:20px;
      display:block;
      padding:8px 18px;
      cursor: pointer;
      position: relative;
    }
    .content .links #categorylink{
      visibility: hidden;
    }
    .content .links li a:hover, .dropdown:hover .dropbtn {
      background-color: #323c4e;
    }
     /* .content .links li .sublink{
      background-color: aqua;
      color:red;
     } */

    .content .links li  .desktop-links{
      display: none;
     
    }

    /* dropdown responsive */
    .content .links .dropdown-content{
      position: relative;
      opacity: 1;
      visibility: visible;
      background: none;
      max-height: 0px;
      /* overflow:hidden; */
    }

    /* .content .links #show-Stores:checked ~ul,
    .content .links #show-Category:checked ~ul
    {
      max-height: 100vh;
    } */
    
    .content .links li:hover .dropdown-content{
       
        max-height: 100vh;
        /* display: grid; */
      }
   
    
    .content .links .dropdown-content .myrow{
      margin:7px 20px;
    }
    .content .links li .dropdown-content .column{
      background-color: #171c24;
       display: grid ;
       grid-template-columns:  repeat(1, 1fr);
       
       /* grid-template-rows: auto; */
      grid-gap: 1px;
      /* opacity: 0; */
      
    }
    .content .links .dropdown-content div a{
      right:  0;
      margin-top: 5%;
      font-size: 18px;
      line-height: 10px;
      border-radius: 3px!important;
    }
    .content .links .dropdown-content div a:hover .column{
      /* font-size: 18px;
      line-height: 10px;
      border-radius: 3px!important; */
      opacity: 1;
    }

    /* left column */

    
    /* Right column */
    /* .row .rightcolumn {
      float: left;
      width: 25%;
      background-color: #f1f1f1;
      padding-left: 20px;
    } */
    @media screen and (max-width:410px) {
      .wrapper nav{
      max-width: 100%;
      padding: 0 20px;
    }

    .dropdown-content .myrow .column{
      padding-top: 10%;
    }
      
    }
  
     /* .column{
      float: left;
      width: 100%;
      padding: 10px;
      background-color: #171c24; 
      
      
     }
    .column a {
      float: none;
      color: black;
      padding: 6px;  
       text-decoration: none;
      display: block;
      text-align: left;
    }    */
  
   
  
  }