/* body{
    margin-top: 20%;
 
} */
.myrow{
    height: 100%;
    margin-top: 10%;
  
  }
  .myrow .content{
    min-height: 100%;
    display: flex;
    flex-direction: row;
  }
  /* leftcolumn */
  .myrow .content .leftcolumn{  
    height: 100%;
    z-index: 1;
    width: 80%;
    /* background-color: aqua; */
  }
  
  /* rightcolumn */
  
  .myrow .content .rightcolumn1{
    width: 20%;
    /* background-color: aqua; */
    z-index: 1;
  
    /* visibility: hidden; */
  }
  .myrow .content .leftcolumn .dealsdetail{
    display: flex;
    flex-direction: column;
    /* width:auto; */
    
    /* background-color: bisque; */
  
  }

  .myrow .content .leftcolumn  .pagelink{
    /* background-color: green; */
    /* margin-left: 30px; */
    margin-bottom: 10px;
    padding-top: 10px;
  
    width:auto;
    color:green;
 
   
  }
  .myrow .content .leftcolumn  .pagelink a{
    color: green;
  }
  .myrow .content .leftcolumn .dealsdetail .sociallink{
    padding-top: 30px;


  }
  .myrow .content .leftcolumn .dealsdetail .title{
   /* align-self: center; */
   padding-left: 20px;
   /* margin-left: 40px; */
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    border: 1px solid green;
    border-radius: 40px;;

  }

  .myrow .content .leftcolumn .dealsdetail .title img{
    border-radius: 40px;
    margin-right: 50px;;
    width:250px;
    height: 250px;
  }

  .myrow .content .leftcolumn .dealsdetail .title h1{
    align-self: center;
    /* background-color: aqua; */
  }
  .myrow .content .leftcolumn .dealsdetail #subtitle{
    font-size: smaller;
   align-self: center;
  }
  .myrow .content .leftcolumn .dealsdetail .divider{
    margin-top: 100px;
    margin-left: 40px;

  }
  .myrow .content .leftcolumn .dealsdetail .sub{
    border: 1px solid green;
    margin-bottom: 40px;
    border-radius: 40px;

  }
  .myrow .content .leftcolumn .dealsdetail .subdeal{
    margin: 40px;
    /* margin-left: 40px; */
    padding-top: 40px;
    padding-bottom: 40px;
    /* margin-bottom: 40px; */

    display: flex;
    flex-direction: row;
    border: 1px solid green;
    border-radius: 40px;
    padding: 30px;

    /* background-color: aqua; */
  }
  .myrow .content .leftcolumn .dealsdetail .subdeal .subdealdata{    
    padding-top: 20px;  
    padding-left: 40px;      
  }
  .myrow .content .leftcolumn .dealsdetail .subdeal .subdealdata #strike{
   
    text-decoration: line-through!important;
  }
 
  .myrow .content .leftcolumn .dealsdetail .subdeal .subdealdata .buynow{
    background-color: green;
    width: auto;
 
 
 padding: 10px; color:#fff;

 align-self: flex-end;
 border-radius: 20px;

 
}
.myrow .content .leftcolumn .dealsdetail .subdeal .subdealdata  .buynow:hover{

  
    background-color: green;
  color:#171c24;
 
 }




/* .subdeal {
    padding: 10px;
    display: flex;
    flex-direction: row;
    border: 1px solid green;
    border-radius: 40px;; */
   
   
    /* z-index: 1; */

    /* justify-content: space-between; */

/* } */
/* .subdeal img{
    height:200px;
    margin-bottom: 10px;
   
    overflow: scroll;
}
.subdeal .buttonlink{
      background-color: black;
   
     margin-left:30%;
   border:#171c24 4px solid;
   padding: 10px; color:#fff;
   margin-top: 30px;
}
.subdeal .buttonlink:hover{

  
   background-color: green;
 color:#171c24;

} */

@media screen and (max-width: 1250px){
  .myrow{
    margin-top: 20%;
  }  
  

  .myrow .content .leftcolumn{   
   
    z-index: 1;
    width: 100%;

  }

  .myrow .content .rightcolumn1{
    width: 0%;
    visibility: hidden;
    /* background-color: bisque; */
  }
}

@media screen and (max-width:900px) {
  .myrow{
    margin-top: 20%;
  }  
  

  .myrow .content .leftcolumn{   
   
    z-index: 1;
    width: 100%;

  }

  .myrow .content .rightcolumn1{
    width: 0%;
    visibility: hidden;
    /* background-color: bisque; */
  }
  .myrow .content .leftcolumn .dealsdetail{
    display: flex;
    flex-direction: column;
    /* width:auto; */
    
    /* background-color: bisque; */
  
  }
  .myrow .content .leftcolumn .dealsdetail .title{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .myrow .content .leftcolumn .dealsdetail .title img{
    /* border-radius: 40px;
    margin-right: 50px;;
    width:250px;
    height: 250px; */
    align-items: center;
    padding-bottom: 10%;
  }

  .myrow .content .leftcolumn .dealsdetail .title h1{
    align-self: center;
    padding-bottom: 10%;
    /* background-color: aqua; */
  }
  .myrow .content .leftcolumn .dealsdetail .sub{
    /* border: 1px solid green;
    margin-bottom: 40px;
    border-radius: 40px; */
    /* display: flex;
    flex-direction: column; */

  }
  .myrow .content .leftcolumn .dealsdetail .subdeal{
    margin: 40px;
    /* margin-left: 40px; */
    padding-top: 40px;
    padding-bottom: 40px;
    /* margin-bottom: 40px; */

    display: flex;
    flex-direction: column;
    border: 1px solid green;
    border-radius: 40px;
    padding: 30px;

    /* background-color: aqua; */
  }
  .myrow .content .leftcolumn .dealsdetail .subdeal .subdealdata{    
    padding-left: 2%;
  }

    .Dealdetail .divider{
        margin-top: 30%;
    }
    /* .flex-container{
        flex-direction: column;
    } */
}

@media screen and (max-width:410px) {

    .Dealdetail .divider{
        margin-top: 30%;
    }
       .flex-container{
        flex-direction: column;
    }

    
}

