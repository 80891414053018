
.signUp{
   margin-left: 30%;
   margin-right:30%;
    padding-top:3%;
   padding-bottom:3%;
}

@media screen and (max-width:1250px){
   .signUp{
      margin-left: 5%;
      margin-right:5%;
   }
}